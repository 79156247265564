import React from "react";
import Dashboard from "../../../organisms/DashboardPageComponents/Dashboard/Dashboard";
import Layout from "../../Layout/Layout";
import PaymentForm from "../../../organisms/RegistrationComponents/Registrationform/PaymentForm";
import SummaryDetails from "../../../organisms/RegistrationComponents/Registrationform/Summary";

const DashboardPage = () => {
  return (
    <Layout isStepper={false} noScrollIs={true} >
      {/* <Dashboard /> */}
      <SummaryDetails/>
      {/* <PaymentForm/> */}
    </Layout>
  );
};

export default DashboardPage;
