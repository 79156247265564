import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import FormControl from '../../../molecules/FormControl/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { applicantSelector, getDistrict, getState, getstaeCityByPinCode } from '../../../../redux/features/applicant/ApplicantSlice';
import { PmayNonPmaySelector, RegisterBidderType } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import { RegistrationStepperSave } from '../../../../redux/features/registration/registrationStepperSlice';


const OrganizationDetails = ({ data }) => {
  const dispatch = useDispatch();
  const [stateList, setStateList] = useState([]);
  const [districList, setDistricList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countries, setCountries] = useState([
    { value: "India", label: "India" },
  ]);
  const { isFetchingBidder,
    isSuccessBidder } = useSelector(PmayNonPmaySelector)

  const [formValues, setFormValues] = useState({
    organizationName: data?.companyName || "",
    address:data?.comprRegisteredAddress ||'',
    mobilenumber:data?.contactPersonMobileNo||""  ,
    faxnumber:data?.compFaxNo||"",
    bid_country: data?.compCountry||"",
    bid_state: data?.compState||"",
    bid_city: data?.compCity||"",
    pin_code: data?.compPostalCode||"",
    bid_district: data?.compDistrict||"",
  });
  const {
    isFetchingApplicantAdd,
    isSuccessReqApplicantAdd,
    isErrorApplicantAdd,
    errorMessageAdd,
    isSuccessResApplicantAdd,
    isFetchingApplicantGet,
    isSuccessResApplicantGet,
    applicantData,
    isSuccessResStateCity,
    StateCityData,
    isSuccessResState,
    StateData,
    isSuccessResDistrict,
    DistrictData,
  } = useSelector(applicantSelector);
  const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
  // const handleSubmit = (values) => {
  //   // Handle form submission logic here
  //   console.log(values);
  // };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(false)
   
    dispatch(RegisterBidderType(values));
};
  const autoCompletePincode = (event) => {
    let pin_code = event.target.value;
    if (pin_code.length == 6) {
      dispatch(getstaeCityByPinCode(pin_code));
    }
  };
  useEffect(() => {
    if (isSuccessBidder) {
        dispatch(RegistrationStepperSave("2"))
    }
}, [isSuccessBidder]);
  useEffect(() => {
    dispatch(getState());
    dispatch(getDistrict());
  }, []);

  useEffect(() => {
    let StateArray = [];
    let DistrictArray = [];
    if (isSuccessResState) {
      if (StateData) {
        StateData.forEach((element) => {
          StateArray.push({ value: element.State, label: element.State });
        });
        setStateList(StateArray);
      }
    }

    if (isSuccessResDistrict) {
      if (DistrictData) {
        DistrictData.forEach((item) => {
          DistrictArray.push({ value: item.District, label: item.District });
        });
        setDistricList(DistrictArray);
      }
    }
  }, [isSuccessResState, isSuccessResDistrict]);
  useEffect(() => {
    if (isSuccessResApplicantGet) {
      let pin_code;
      if (applicantData.PresentPincode) {
        if (applicantData.PresentPincode !== "0") {
          pin_code = applicantData.PresentPincode;
        } else {
          pin_code = "";
        }
      }
      let city_town;
      if (applicantData.PresentCity) {
        if (applicantData.PresentCity !== "0") {
          city_town = applicantData.PresentCity;
        } else {
          city_town = "";
        }
      }
      let district_val;
      if (applicantData.PresentDistrict) {
        if (applicantData.PresentDistrict !== "0") {
          district_val = applicantData.PresentDistrict;
        } else {
          district_val = "";
        }
      }
      let state_val;
      if (applicantData.PresentState) {
        if (applicantData.PresentState !== "0") {
          state_val = applicantData.PresentState;
        } else {
          state_val = "";
        }
      }

      if (pin_code && city_town && district_val && state_val) {
        const savedValues = {
          pincode: pin_code,
          village: city_town,
          district: district_val,
          state: state_val,
        };

        setFormValues(savedValues);
      }
    }
  }, [applicantData, isSuccessResApplicantGet]);
  return (
    <Formik initialValues={formValues} enableReinitialize onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>  <Grid container spacing={1}>
          <Grid item xs={6}>
            <FormControl
              control="input"
              variant="outlined"
              label="Organization Name"
              placeholder="Organization Name"
              name="organizationName"
              type="text"
              id="organizationName"
              required
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              control="input"
              variant="outlined"
              label="Address"
              placeholder="Address"
              name="address"
              type="text"
              id="address"
              required
              margin="dense"
            />
          </Grid>
          
        
          <Grid item xs={3}>
            <FormControl
              control="selectbox"
              variant="outlined"
              label="District"
              options={districList}
              placeholder="district"
              name="bid_district"
              type="text"
              id="bid_district"
              required
              margin="dense"

            />
          </Grid>
          <Grid item xs={3}>
            <FormControl
              control="input"
              variant="outlined"
              label="City"
              placeholder="City"
              name="bid_city"
              type="text"
              id="bid_city"
              inputProps={{ maxLength: 150 }}
              required
              margin="dense"

            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              control="input"
              variant="outlined"
              label="Pincode"
              placeholder="Pincode"
              name="pin_code"
              type="number"
              id="pin_code"
              required

              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              onChange={(e) => {
                autoCompletePincode(e);
                const pinCode = e.target.value;
                setFieldValue("pin_code", pinCode);
              }}
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              control="selectbox"
              variant="outlined"
              label="State"
              placeholder="State"
              name="bid_state"
              type="text"
              id="bid_state"
              // options={states.length === 0 ? [{ value: selectedCountry, label: selectedCountry }] : states?.map(state => ({
              //     value: state.state_code,
              //     label: state.name,
              // }))}
              options={stateList}

              required
              // onChange={(e) => {
              //     setFieldValue("state", e.target.value);
              // }}

              margin="dense"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl
              control="selectbox"
              options={countries}
              // options={countries.map(country => ({
              //     value: country.name,
              //     label: country.name,
              // }))}
              onChange={(e) => {
                setFieldValue("bid_country", e.target.value);
                setSelectedCountry(e.target.value);
              }}
              variant="outlined"
              label="Country"
              placeholder="Country"
              name="bid_country"
              type="text"
              id="bid_country"
              required

              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              control="input"
              variant="outlined"
              label="Mobile Number"
              placeholder="Mobile Number"
              name="mobilenumber"
              type="text"
              id="mobilenumber"
              required
              margin="dense"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl
              control="input"
              variant="outlined"
              label="Fax Number"
              placeholder="Fax Number"
              name="faxnumber"
              type="text"
              id="faxnumber"
              required
              margin="dense"
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default OrganizationDetails;
