import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { ColorlibConnector } from '../../ProfilePageComponents/MakeHousePayment/MakeHousePayment.style';
import { useDispatch, useSelector } from 'react-redux';
import { RegistrationStepperSelector, clearRegistrationStepperData, getRegistrationProgress } from '../../../../redux/features/registration/registrationStepperSlice';
import { ProjectStsCompletedIcon } from '../../../atoms/SvgIcons/SvgIcons';

// Custom Connector
const CustomConnector = withStyles({
  alternativeLabel: {
    top: 10,
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

// Custom Step Icon
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 10,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

// Custom Colorlib Step Icon
const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage: 'linear-gradient(136deg, rgb(255, 223, 0) 0%, rgb(255, 200, 0) 50%, rgb(255, 180, 0) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage: 'linear-gradient(136deg, rgb(76, 175, 80) 0%, rgb(56, 142, 60) 50%, rgb(46, 125, 50) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <ProjectStsCompletedIcon />,
    2: <ProjectStsCompletedIcon />,
    3: <ProjectStsCompletedIcon />,
    4: <ProjectStsCompletedIcon />,
    5: <ProjectStsCompletedIcon />,
    6: <ProjectStsCompletedIcon />,
  };

  const defaultIcon = <div className={classes.circle} />;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? icons[String(props.icon)] : defaultIcon}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

// Stepper Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function HorizontalLinearStepper() {
  const { 
    isFetchRegStepper,
    isSuccessgetRegStepper,
    getRegStepper,
    getRegActiveStep,
    isErrorgetRegStepper,
    getRegStepperData,
    errorMessagegetRegStepper,
    isSuccessRegStepper
  } = useSelector(RegistrationStepperSelector);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getRegistrationProgress());
    dispatch(clearRegistrationStepperData());
  }, [isSuccessRegStepper]);

  const steps = [
    { label: "Organization Details" },
    { label: "KYC Details" },
    { label: "Details of Exhibition / Event" },
    { label: "Period of Exhibition / Event" },
    { label: "Contact Person Details" },
    { label: 'Upload Documents' },
  ];

  useEffect(() => {
    if (isSuccessgetRegStepper) {
      setActiveStep(getRegActiveStep);
    }
  }, [isSuccessgetRegStepper, getRegActiveStep]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep - 1} connector={<CustomConnector />} orientation="horizontal">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
