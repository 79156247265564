import React, { useEffect, useState } from 'react';
import { Box, Popover, Card, CardContent, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from '@mui/material';
import HorizontalLinearStepper from '../Stepper/HorizontalLinearStepper';
import { Button, makeStyles, withStyles } from '@material-ui/core';
import barcode from '../../../../assets/barcode.png';
import { useDispatch, useSelector } from 'react-redux';
import { PmayNonPmaySelector, getSummaryDetails } from '../../../../redux/features/pmayNonPmay/pmayNonPmaySlice';
import moment from 'moment/moment';
import mmrda from '../../../../assets/mmrda.png';
import jsPDF from 'jspdf';
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: 'white !important',
        fontWeight: 'bold !important'
    },
    body: {
        fontSize: 10,
        padding: '0px',
        margin: '0px'
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const SummaryDetails = () => {
    const dispatch = useDispatch();
    const { summaryData, isSuccessResSummary } = useSelector(PmayNonPmaySelector);
    const [summary, setSummary] = useState({});
    const [payment, setPayment] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    useEffect(() => {
        dispatch(getSummaryDetails());
    }, [dispatch]);

    useEffect(() => {
        if (isSuccessResSummary) {
            setSummary(summaryData[0]);
            setPayment(summaryData?.PaymentDetails[0]); // Assuming summaryData is an array
        }
    }, [isSuccessResSummary, summaryData]);
    console.log(payment, "dsfdsd")
    const useStyles = makeStyles({
        table: {
            minWidth: 700,
        },
        smallColumn: {
            maxWidth: 100,
        },
    });
    const classes = useStyles();
    const options = [
        {
            value: '1', label: "Any Organizations /Institution (All Institutes / Organizations except Govt.Offices/Organization) for any program but excluding Circus. Government - Semi Government, Public Organizations (for non commercial purpose) "},
        { value: '2', label: 'Government Organizations/Institutes' },

    ];
    const selectedOption = summary?.ApplicantCatId === '1' ? options[0].label : options[1].label;
    // const getPeriodDates = (eventPeriod) => {
    //     const periods = JSON.parse(eventPeriod);
    //     const fromDates = periods?.map(period => new Date(period.from));
    //     const toDates = periods?.map(period => new Date(period.to));
    //     const minFromDate = new Date(Math.min(...fromDates))?.toISOString().split('T')[0];
    //     const maxToDate = new Date(Math.max(...toDates))?.toISOString().split('T')[0];
    //     return { minFromDate, maxToDate };
    // };

    const getPeriodDates = (eventPeriod) => {
        try {
            const periods = JSON.parse(eventPeriod);
            const fromDates = periods?.map(period => new Date(period.from));
            const toDates = periods?.map(period => new Date(period.to));
            const minFromDate = new Date(Math.min(...fromDates)).toISOString().split('T')[0];
            const maxToDate = new Date(Math.max(...toDates)).toISOString().split('T')[0];
            return { minFromDate, maxToDate };
        } catch (error) {
            console.error('Error parsing eventPeriod:', error);
            return { minFromDate: '', maxToDate: '' };
        }
    };

    const periodDates = summary?.EventPeriod ? getPeriodDates(summary.EventPeriod) : { minFromDate: '', maxToDate: '' };

    const generatePDF = () => {
        const doc = new jsPDF();
    
        // Add the logo
        const imgData = mmrda; // Base64 encoded image or imported image file
        doc.addImage(imgData, 'PNG', 10, 10, 50, 20); // Position the image
    
        // Add text and lines to mimic the layout
        doc.setFontSize(14);
    
        // Header
        doc.text('Application Fees Receipt', 105, 20, null, null, 'center');
        doc.setFontSize(10);
        doc.text(`Receipt No : ${Math.floor(Math.random() * 10000000000000)}`, 150, 30, null, null, 'right');
        doc.text(`Transaction Id : TXN${Math.random().toString(36).substring(2).toUpperCase()}`, 150, 40, null, null, 'right');
    
        // Application details
        doc.text(`Application No.: ${summary?.ApplicationNo}`, 20, 50);
      
    
        doc.text('Applicant Name', 20, 70);
        doc.text(`${summary?.FirstName}`, 70, 70);
    
        doc.text('Paid By', 20, 80);
        doc.text('Paid By Card', 70, 80);
    
        doc.text('Total Paid Amount', 20, 90);
        doc.text(`₹ ${payment?.Amount}`, 70, 90);
    
        doc.text(`Paid At ${payment?.CreatedOn}`, 20, 100);
    
        // Payment summary
        doc.text('Payment Summary', 20, 120);
        doc.text(`Application Fee : ₹ ${payment?.Amount}`, 20, 130);
        doc.text('GST(0%) : ₹ 0.00', 20, 140);
        doc.text('Total Amount :', 20, 150);
        doc.text(`₹ ${payment?.Amount}`, 70, 150);
    
        doc.save('receipt.pdf');
      };

    return (
        <Card sx={{ maxWidth: 900, margin: 'auto', mt: 4 }}>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 1, background: "linear-gradient(126deg, #00a74a, #15489f)", color: 'white' }}>
                    Application Details
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img width={150} height={100} src={barcode} alt='barcode' />
                    <Box>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold', color: 'grey' }}>
                            Application No : {summary?.ApplicationNo}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Application Date : {payment?.CreatedAt ? moment(payment.CreatedAt).format("DD-MMM-YYYY") : ''}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Period From : {periodDates.minFromDate ? moment(periodDates.minFromDate).format("DD-MMM-YYYY") : ''}
                        </Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: 'bold' }}>
                            Period To : {periodDates.maxToDate ? moment(periodDates.maxToDate).format("DD-MMM-YYYY") : ''}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={4}>
                        <Typography variant="h6" component='div' sx={{ fontWeight: 'bold', color: 'blue' }}>{summary?.companyName}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.comprRegisteredAddress} , {summary?.compDistrict}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.compState} , {summary?.compCountry}</Typography>
                        <Typography variant="body" component='div' sx={{ fontWeight: '600', color: 'green' }}>{summary?.compPostalCode}</Typography>
                        <Button onClick={generatePDF} variant="contained" color="primary" style={{ margin: 5 }}>
            Download Receipt
          </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body" component="div" gutterBottom align="center" sx={{ fontWeight: 'bold', alignSelf: "center" }}>
                            Steps Completed
                        </Typography>
                        <HorizontalLinearStepper />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Registration No</Typography>
                        <Typography>{summary?.compRegistrationNumber}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Name</Typography>
                        <Typography>{summary?.EventName}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Purpose</Typography>
                        <Typography>{summary?.Purpose}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Exhibition/Event Category</Typography>
                        {summary?.ApplicantCatId === '1' ?
                            <div>        <Typography
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            >Any Organizations / Institution
                            </Typography>
                                <Popover
                                    id="mouse-over-popover"
                                    sx={{
                                        pointerEvents: 'none',
                                    }}
                                    open={open}
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography sx={{ p: 1 }}>I {selectedOption}</Typography>
                                </Popover></div> : <Typography>{selectedOption}</Typography>}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Period of Exhibition / Event:</Typography>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} style={{ marginTop: 3 }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Purpose</StyledTableCell>
                                        <StyledTableCell align="center">Period</StyledTableCell>
                                        <StyledTableCell align="center">No. of Days</StyledTableCell>
                                        <StyledTableCell align="center">Area Required (Sq. mt.)</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {summary?.EventPeriod && JSON.parse(summary?.EventPeriod)?.map((entry, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell>{entry.purpose}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.from} to {entry.to}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.days}</StyledTableCell>
                                            <StyledTableCell align="center">{entry.area}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Typography variant="body" sx={{ fontWeight: 'bold' }}>Payment Details:</Typography>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Transaction Id</Typography>
                        <Typography>{payment?.TransId}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Application No.</Typography>
                        <Typography>{summary?.ApplicationNo}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Applicant Name</Typography>
                        <Typography>{summary?.FirstName}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid By</Typography>
                        <Typography>Card</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Total Paid Amount</Typography>
                        <Typography>₹ {payment?.Amount}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body" sx={{ fontWeight: 'bold' }}>Paid At</Typography>
                        <Typography>{payment?.CreatedOn}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SummaryDetails;
